import { styled } from "@mui/material";

const ErrorWrapper=styled('div')`

width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
/* background: rgba(7,143,205,255); */
img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
h1{
    font-size: 1.5rem;
    font-weight: 600;
    color: rgba(7,143,205,255);
}
p{
    font-size: 0.8rem;
    font-weight: 400;
    color: rgba(7,143,205,255);
    
}
button{
    padding: 10px 10px;
    border-radius: 10px;
    background-color: rgba(7,143,205,255);
    font-size: 1rem;
    line-height: 19px;
    font-weight: 400;
    color: #fff;
    border: 0;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;


    display: flex;
    align-items: center;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    outline: 0;
    &:focus {
      box-shadow: 0 0 0 1px rgba(7,143,205,255);
    }

}
`

export default ErrorWrapper;