import React from "react";
import { useRouter } from "next/router";
import ErrorWrapper from "./style";

const Error404 = () => {
  const router = useRouter();

  const handleClick =()=>{
    router.push("/")
  }
  return (
    <ErrorWrapper>
       <img src="/static/images/error-404.png" alt="404" />
          <h1>404 xato - sahifa topilmadi</h1>
          <p className="description">
          Siz kirishga urinayotgan sahifa topilmadi. U ko&apos;chirilgan, o&apos;chirilgan yoki URL noto&apos;g&apos;ri kiritilgan bo&apos;lishi mumkin.
          </p>
          <button onClick={handleClick}>Bosh sahifaga o&apos;tish</button>
    </ErrorWrapper>
  );
};

export default Error404;
